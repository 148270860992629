exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-gatsby-tsx": () => import("./../../../src/pages/gatsby.tsx" /* webpackChunkName: "component---src-pages-gatsby-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mitgliedschaft-tsx": () => import("./../../../src/pages/mitgliedschaft.tsx" /* webpackChunkName: "component---src-pages-mitgliedschaft-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-spenden-tsx": () => import("./../../../src/pages/spenden.tsx" /* webpackChunkName: "component---src-pages-spenden-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */)
}

